import request from '@manage/utils/request'

export function handleLogin(data) { //登陆
  return request({
    url: 'user/out/login',
    method: 'post',
    data
  })
}


export function handleGetPitureCode() { //获取图片验证码
  return request({
    url: 'picturecode/out/getPitureCode',
    method: 'get',
  })
}

export function handleGetSms(phone) {//获取验证码
  return request({
    url: 'assist/out/getValidateCode',
    method: 'post',
    params: {
      phone: phone
    }
  })
}

export  function handleSavePublicMatchAuth(data){
return request({
    url: 'applypublicmatchauth/out/apply',
    method: 'post',
    data
  })
}
