import common from '@manage/utils/common';

import {
  handleLogin,
  handleGetPitureCode,
  // handleGetSms
} from '@manage/api/login';
import {
  JSEncrypt
} from "jsencrypt";
export default {
  name: 'Login',
  components: {},
  data() {
    return {
      pitureCodeObj: {},
      form: {
        phone: '',
        password: '',
        pitureCode:''
      },
      loginRules: {
        phone: [{ required: true, trigger: 'blur' }],
        password: [{ required: true, trigger: 'blur' }]
      }
    }
  },
  watch: {

  },
  created() {
    this.getPitureCode();
	document.addEventListener('keydown',this.listenEvent,false)
  },
  mounted() {

  },
  destroyed() {
    // window.removeEventListener('storage', this.afterQRScan)
	document.removeEventListener('keydown',this.listenEvent);
  },
  methods: {
    clickPitureCode() { //单击图片验证码
      this.getPitureCode();
      this.form.pitureCode = "";
    },
    getPitureCode() { //获取图片验证码
      handleGetPitureCode().then(res => {
        if (res.status == 200) {
          this.pitureCodeObj = {
            pictureId: res.data.id,
            src: res.data.src
          }
          this.form.pictureId = res.data.id;
        }

      });
    },
    pwdLogin() { //密码登陆
      let encrypt = new JSEncrypt();
      var requestData = {
        ...this.form
      };

      try { //验证
        common.requiredValidate([{
          value: this.form.phone,
          tips: "登陆账户(联系方式)不能为空!"
        }, {
          value: this.form.password,
          tips: "密码不能为空!"
        }, {
          value: this.form.pitureCode,
          tips: "验证码不能为空!"
        }]);
      } catch (e) {
        this.$message({
          type: 'error',
          message: e
        });
        return;
      }
      encrypt.setPublicKey(common.publicKey);
      requestData.password = encrypt.encrypt(this.form.password);
      this.loading = true;
      this.loginRequest(requestData);
    },
    loginRequest(requestData) {//立即登陆
	requestData={...requestData,adminFlag:'admin'};
      handleLogin(requestData).then(res => {
        if (res.status == 200) {
          this.$message({
            type: 'success',
            message: res.msg
          });
          sessionStorage.setItem("user", JSON.stringify(res.data.user));
          sessionStorage.setItem("auth", JSON.stringify(res.data.oauth2Info));
          sessionStorage.setItem('token', 'bearer ' + res.data.oauth2Info.access_token);
          sessionStorage.setItem('loginUserId', res.data.user.id);
          this.$router.push({ path: '/manage' });
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          });
        }
      })
    },
	listenEvent(event) {
		if (event.keyCode === 13) {			
			this.pwdLogin();
		}
	}
  }
}
