// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./bg.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".page[data-v-5d9a4430]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:cover;background-repeat:no-repeat;background-position:50%;-webkit-user-select:none}.form[data-v-5d9a4430],.page[data-v-5d9a4430]{width:100%;height:100%}.form[data-v-5d9a4430]{position:relative}.form .logo-icon[data-v-5d9a4430]{margin-bottom:10px;cursor:pointer}.form .form-content[data-v-5d9a4430]{position:absolute;top:50%;left:50%;transform:translate(-50%,-50%);width:350px;margin:auto;text-align:center;background-color:hsla(0,0%,100%,.7);background-color:hsla(0,0%,100%,.98);border-radius:10px;padding:20px 20px}.form .form-content .form-title[data-v-5d9a4430]{color:#1485fe;font-size:24px;font-weight:bolder;padding-bottom:30px}.form .form-content[data-v-5d9a4430]  .el-form-item{padding:15px!important;background:#fff}.form[data-v-5d9a4430]  .el-input-group__prepend{background:transparent;border-color:transparent}.form .el-input-group--prepend .el-input__inner[data-v-5d9a4430]{border:none!important}.form .submit-btn[data-v-5d9a4430]{width:100%;height:48px;line-height:48px;padding:0;font-size:16px!important;margin-top:25px;background-color:#3e50f7!important;border-radius:24px}.vidate-code[data-v-5d9a4430]{position:absolute;right:1px;top:2px;cursor:pointer;width:100px;height:32px;border-left:1px solid #eee}", ""]);
// Exports
module.exports = exports;
